import {PropsWithChildren} from "react";

interface Props {
    className?: string;
}

export const Section = ({children}: PropsWithChildren<Props>) => <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
}}>
    {children}
</div>;