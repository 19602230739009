import {getError} from "../utils/get-error";

export const ErrorMessage = ({name}: {
    name?: string
}) => {
    const error = getError(name);
    return <p
        style={{
            color: '#ED652A',
        }}
    >
        {error}
    </p>
}