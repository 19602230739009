import {getError} from "../utils/get-error";
import {ErrorMessage} from "./ErrorMessage";

interface Props {
    name?: string;
    value: string;
    placeholder?: string;
    type?: string;
    setValue: (s: string) => void;
    selectAllOnFocus?: boolean;
}

export const Input = ({
                          name,
                          value,
                          setValue,
                          placeholder,
                          type = 'text',
                          selectAllOnFocus = false
                      }: Props) => {
    const error = getError(name);
    return <>
        <input
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
                // on any change reset all errors
                window.ERRORS = {};
                setValue(e.target.value.trim());
            }}
            onFocus={(event) => {
                if (selectAllOnFocus) {
                    // Wrapping select() in setTimeout to make it work in Safari
                    // https://stackoverflow.com/questions/54229359/why-does-select-not-work-on-safari-with-reactjs
                    setTimeout(() => event.target.select(), 0);
                }
            }}
            style={{
                padding: 24,
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.08)",
                borderRadius: 16,
                fontSize: '1.25rem',
                outline: 'none',
                border: `1px solid ${error ? '#ED652A' : 'transparent'}`,
            }}
        />
        <ErrorMessage name={name}/>
    </>;
}