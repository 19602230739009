import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

interface Props {
    title: string;
    type?: 'submit' | 'reset' | 'button';
    isFetching: boolean;
    isDisabled: boolean;
    onClick?: () => void;
}

export const Button = ({isFetching, isDisabled, onClick, type = 'button', title}: Props) => <button
    type={type}
    onClick={() => onClick?.()}
    disabled={isDisabled}
    style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: (isFetching || isDisabled) ? '#E4E7EB' : '#3F5D81',
        borderRadius: 16,
        cursor: (isFetching || isDisabled) ? 'not-allowed' : 'pointer',
        flexGrow: 1,
        height: 80,
        marginBottom: 14,
    }}
>
    {isFetching && <ClipLoader
        size={20}
        color={'#B5B5B5'}
        cssOverride={{marginRight: 5}}
    />}
    <span
        style={{
            fontSize: '1.2rem',
            color: (isFetching || isDisabled) ? '#3F5D81' : 'white',
        }}
    >
        {title}
    </span>
</button>
