import {PropsWithChildren} from "react";


interface Props {
    className?: string;
}

export const Subtitle = ({children, className}: PropsWithChildren<Props>) => <p
    className={className}
    style={{
        fontSize: '1rem',
    }}>
    {children}
</p>;