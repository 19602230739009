import {ReactComponent as CheckmarkIcon} from '../assets/svgs/checkmark.svg';
import {getError} from "../utils/get-error";
import {ErrorMessage} from "./ErrorMessage";
import {ReactNode} from "react";

interface Props {
    name?: string;
    label: string | ReactNode;
    toggled: boolean;
    onToggle: () => void;
}

export const RadioButton = ({name, label, toggled, onToggle}: Props) => {
    const error = getError(name);
    return <>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            gap: 24,
        }}>
            <label
                htmlFor={`radio-button-${name}`}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 30,
                    height: 30,
                    flexShrink: 0,
                    borderRadius: 10,
                    background: toggled ? '#3F5D81' : 'transparent',
                    border: `1px solid ${error ? '#ED652A' : toggled ? 'transparent' : '#D3D3D3'}`
                }}>
                <CheckmarkIcon style={{
                    opacity: Number(toggled),
                }}/>
            </label>
            <input
                name={name}
                id={`radio-button-${name}`}
                type={'checkbox'}
                checked={toggled}
                onChange={() => {
                    // on any change reset all errors
                    window.ERRORS = {};
                    onToggle();
                }}
                style={{display: 'none'}}
            />
            <label
                htmlFor={`radio-button-${name}`}
                style={{
                    fontSize: '1.25rem',
                }}
            >
                {label}
            </label>
        </div>
        <ErrorMessage name={name}/>
    </>
}