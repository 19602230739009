import React, {FormEvent, useEffect, useState} from 'react';
import './App.css';
import {Title} from "./components/Title";
import {Subtitle} from "./components/Subtitle";
import {Section} from "./components/Section";
import {Input} from "./components/Input";
import {RadioButton} from "./components/RadioButton";
import {Button} from "./components/Button";

function App() {
    const [email, setEmail] = useState(localStorage.getItem("email") ?? "");
    const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("phone") ?? "+7");
    const [confirmRefundPolicy, setConfirmRefundPolicy] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [isDisabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(email.length === 0 || phoneNumber.length < 12 || !confirmRefundPolicy);
    }, [email, phoneNumber, confirmRefundPolicy])

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        if (isDisabled || isFetching) {
            return;
        }

        try {
            localStorage.setItem("email", email);
            localStorage.setItem("phone", phoneNumber);
            setFetching(true);
        } catch (e) {
            setFetching(false);

            throw e;
        }
    }
    return (
        <form className={"App-container"} onSubmit={onSubmit} method={"GET"}>
            <Title>
                {window.COMMENT || 'Оплата'}
            </Title>
            <Section>
                <Subtitle>
                    По закону мы обязаны отослать онлайн-чек на ваш e-mail
                </Subtitle>
                <Input
                    name={"email"}
                    type={"email"}
                    value={email}
                    setValue={setEmail}
                    placeholder={"user@gmail.com"}
                    selectAllOnFocus={true}
                />
            </Section>
            <Section>
                <Subtitle>
                    Введите номер телефона
                </Subtitle>
                <Input
                    name={"phone_number"}
                    type={"tel"}
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    placeholder={"+79311234567"}
                />
            </Section>
            <Section>
                <RadioButton
                    name={'refund-policy'}
                    label={<span>
                        Соглашаюсь с условиями использования, <a
                        href='https://anonchat.io/privacy.pdf'
                        target="_blank"
                        rel="noreferrer"
                    >политикой конфиденциальности</a> и <a
                        href='https://anonchat.io/moneyback.pdf'
                        target="_blank"
                        rel="noreferrer"
                    >возвратов</a> при
                        оплате картой</span>}
                    toggled={confirmRefundPolicy}
                    onToggle={() => setConfirmRefundPolicy((prev) => !prev)}
                />
            </Section>
            <Section>
                <Button
                    type={"submit"}
                    title={"Далее"}
                    isFetching={isFetching}
                    isDisabled={isDisabled}
                />
            </Section>
        </form>
    );
}

export default App;
